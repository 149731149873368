<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>الأرباح</h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li>
                                            <router-link to="/my"
                                                >الرئيسية</router-link
                                            >
                                        </li>
                                        <li class="active">الأرباح</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column col-lg-12">
                            <div class="my-invoices">
                                <div class="row">
                                    <div class="col-md-1">
                                        <button
                                            class="btn btn-info w-100"
                                            @click="resetDates()"
                                        >
                                            <i class="la la-refresh"></i>
                                        </button>
                                    </div>
                                    <div class="col-md-4">
                                        <input
                                            id="from"
                                            placeholder="من"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <input
                                            id="to"
                                            placeholder="إلى"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="col-md-3 text-center">
                                        <h4>{{ profit }} ريال</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="my-invoices">
                                <div class="inner-container clearfix">
                                    <div class="invoice-box">
                                        <div class="table-responsive">
                                            <table
                                                id="invoices-table"
                                                class="table table-striped table-bordered table-hover"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>عنوان العقار</th>
                                                        <th>تاريخ الطلب</th>
                                                        <th>الدخول</th>
                                                        <th>الخروج</th>
                                                        <th>حفلة</th>
                                                        <th>الإجمالى</th>
                                                        <th>نسبة الموقع</th>
                                                        <th>الربح</th>
                                                        <th>الحالة</th>
                                                        <!-- <th>
                                                            <i
                                                                class="la la-cog"
                                                            ></i>
                                                        </th> -->
                                                    </tr>
                                                </thead>
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import flatpickr from "flatpickr";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
import "@/plugins/datatables";
import $ from "jquery";

export default {
    name: "my.invoices",
    data() {
        return {
            table: null,
            profit: 0,
        };
    },
    mounted() {
        this.prepareTable();
        this.getProfit();

        flatpickr("#from", {
            locale: Arabic,
            onChange: () => {
                this.getProfit();
                this.updateTable();
            },
        });
        flatpickr("#to", {
            locale: Arabic,
            onChange: () => {
                this.getProfit();
                this.updateTable();
            },
        });
    },
    methods: {
        resetDates() {
            $("#from").val("");
            $("#to").val("");
            this.getProfit();
            this.updateTable();
        },
        getProfit() {
            this.$axios
                .get("/my/calculate-profit", {
                    params: {
                        from: $("#from").val(),
                        to: $("#to").val(),
                    },
                })
                .then((res) => {
                    this.profit = res.data.totalProfit;
                });
        },
        actions(row) {
            return this.invoiceDetails(row);
        },
        invoiceDetails(row) {
            return `<button class="btn btn-secondary" v-on="click, redirectToInvoice(${row.id})" ><i class="la la-file" ></i></button>`;
        },
        redirectToInvoice(invoice_id) {
            this.$route.push({
                name: "invoice",
                params: { invoice_id: invoice_id },
            });
        },
        prepareTable() {
            this.table = $("#invoices-table")
                .DataTable({
                    ajax: {
                        url: process.env.VUE_APP_API_BASE_URL + "/my/invoices",
                        data: (d) => {
                            d.from = $("#from").val();
                            d.to = $("#to").val();
                        },
                        crossDomain: true,
                        xhrFields: { withCredentials: true },
                        beforeSend: () => {
                            this.$loading.show();
                        },
                    },
                    serverSide: true,
                    stateSave: true,
                    columns: [
                        { data: "reservation.id" },
                        { data: "reservation.title" },
                        { data: "reservation.created_at" },
                        {
                            name: "reservation.from_day",
                            data: null,
                            render: (row) => {
                                return (
                                    row.reservation.from_day +
                                    " " +
                                    (row.reservation.from_period +
                                        " " +
                                        (row.reservation.from_period == 1
                                            ? "فترة أولى"
                                            : row.reservation.from_period == 2
                                            ? "فترة ثانية"
                                            : "فترة ثالثة"))
                                );
                            },
                        },
                        {
                            name: "reservation.to_day",
                            data: null,
                            render: (row) => {
                                return (
                                    row.reservation.to_day +
                                    " " +
                                    (row.reservation.to_period +
                                        " " +
                                        (row.reservation.to_period == 1
                                            ? "فترة أولى"
                                            : row.reservation.to_period == 2
                                            ? "فترة ثانية"
                                            : "فترة ثالثة"))
                                );
                            },
                        },
                        {
                            data: "reservation.party",
                            render: (party) => {
                                switch (party) {
                                    case "birthday":
                                        return "عيد ميلاد";
                                        break;
                                    case "wedding":
                                        return "زفاف";
                                        break;
                                    case "other":
                                        return "أخرى";
                                        break;
                                    default:
                                        return "لا يوجد";
                                        break;
                                }
                            },
                        },
                        { data: "reservation.total" },
                        {
                            data: "reservation.percent",
                            render: (percent) => {
                                return percent + " %";
                            },
                        },
                        { data: "advertiser_profit" },
                        {
                            data: "reservation.status",
                            render: (status) => {
                                switch (status) {
                                    case "pending":
                                        return "بانتظار تأكيد الدفع";
                                        break;
                                    case "authorized":
                                        return "تم تأكيد الدفع من الزبون";
                                        break;
                                    case "accepted":
                                        return "تم الموافقة وسحب المبلغ";
                                        break;
                                    case "request_cancel":
                                        return "تم طلب الإلغاء من الإدارة";
                                        break;
                                    case "canceled":
                                        return "تم الموافقة على الإلغاء";
                                        break;
                                    case "finished":
                                        return "إنتهى";
                                        break;
                                    case "rated":
                                        return "تم التقييم";
                                        break;
                                }
                            },
                        },
                        // {
                        //     data: null,
                        //     render: (row) => {
                        //         return this.actions(row);
                        //     },
                        //     searchable: false,
                        //     orderable: false,
                        // },
                    ],
                })
                .on("draw", () => {
                    this.$loading.hide(0);
                    $("[v-on]").each((index, element) => {
                        let ob = ((attr) => ({
                            event: /(\w+),/.exec(attr)[1],
                            handler: /, ?(.*)/.exec(attr)[1],
                        }))($(element).attr("v-on"));
                        let f = /(\w+)\(?/.exec(ob.handler)[1];
                        let args = /\w\((.*)\)/
                            .exec(ob.handler)[1]
                            .split(",")
                            .map((v) => v.trim());
                        $(element).on(ob.event, () => {
                            this[f].apply(this, args);
                        });
                    });
                });
        },
        updateTable() {
            this.table.ajax.reload(null, false);
        },
    },
};
</script>

<style>
@import "https://cdn.datatables.net/1.10.22/css/jquery.dataTables.min.css";
@import "../../../../../node_modules/flatpickr/dist/flatpickr.min.css";
</style>